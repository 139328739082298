<!--
#***********************************************
#
#      Filename: src/views/Detail/Discount.vue
#
#        Author: wwj - 318348750@qq.com
#       Company: 甘肃国臻物联网科技有限公司
#   Description: 优免明细
#        Create: 2021-11-11 15:34:02
# Last Modified: 2021-11-11 15:39:37
#***********************************************
-->
<template>
  <div class="app-container">
    <!-- 数据筛选 -->
    <ContentHeader
      class="m-b-20"
      :settings="settings"
      :table-head-fields.sync="tableHeadFields"
      :total-item="total"
      :permissions="permissions"
      @filter-change="filterChange"
      @refresh="handleRefresh"
      @export="handleExport" />
    <!-- end 数据筛选 -->

    <!-- 数据Table -->
    <el-table :data="dataList" stripe size="mini" border @selection-change="changeSelectItems">
      <el-table-column fixed="left" type="selection" :width="50" align="center" />
      <el-table-column
        v-if="tableHeadFields[0].isShow"
        :label="tableHeadFields[0].label"
        :width="tableHeadFields[0].width"
        :align="tableHeadFields[0].align"
        :prop="tableHeadFields[0].prop" />
      <el-table-column
        v-if="tableHeadFields[1].isShow"
        :label="tableHeadFields[1].label"
        :width="tableHeadFields[1].width"
        :align="tableHeadFields[1].align"
        :prop="tableHeadFields[1].prop" />
      <el-table-column
        v-if="tableHeadFields[2].isShow"
        :label="tableHeadFields[2].label"
        :width="tableHeadFields[2].width"
        :align="tableHeadFields[2].align"
        :prop="tableHeadFields[2].prop" />
      <el-table-column
        v-if="tableHeadFields[3].isShow"
        :label="tableHeadFields[3].label"
        :width="tableHeadFields[3].width"
        :align="tableHeadFields[3].align"
        :prop="tableHeadFields[3].prop" />
      <el-table-column
        v-if="tableHeadFields[4].isShow"
        :label="tableHeadFields[4].label"
        :width="tableHeadFields[4].width"
        :align="tableHeadFields[4].align"
        :prop="tableHeadFields[4].prop" />
      <el-table-column
        v-if="tableHeadFields[5].isShow"
        :label="tableHeadFields[5].label"
        :width="tableHeadFields[5].width"
        :align="tableHeadFields[5].align"
        :prop="tableHeadFields[5].prop" />
      <el-table-column
        v-if="tableHeadFields[6].isShow"
        :label="tableHeadFields[6].label"
        :width="tableHeadFields[6].width"
        :align="tableHeadFields[6].align"
        :prop="tableHeadFields[6].prop">
        <template slot-scope="scope">
          <el-popover v-if="scope.row.parkingOrder.cloudEnterPicture" placement="right" width="200" trigger="click">
            <i slot="reference" class="el-icon-picture-outline-round">点击显示</i>
            <el-image
              :src="scope.row.parkingOrder.cloudEnterPicture + '?x-oss-process=image/resize,m_fill,w_900,h_500'" />
          </el-popover>
          <i v-else class="el-icon-warning-outline">暂无图片</i>
        </template>
      </el-table-column>
      <el-table-column
        v-if="tableHeadFields[7].isShow"
        :label="tableHeadFields[7].label"
        :width="tableHeadFields[7].width"
        :align="tableHeadFields[7].align"
        :prop="tableHeadFields[7].prop">
        <template slot-scope="scope">
          {{ parseFloat(scope.row.limitPrice).toFixed(2) }}
        </template>
      </el-table-column>
      <el-table-column
        v-if="tableHeadFields[8].isShow"
        :label="tableHeadFields[8].label"
        :width="tableHeadFields[8].width"
        :align="tableHeadFields[8].align"
        :prop="tableHeadFields[8].prop">
        <template slot-scope="scope">
          {{ parseFloat(scope.row.actualDiscountPrice).toFixed(2) }}
        </template>
      </el-table-column>
      <el-table-column
        v-if="tableHeadFields[9].isShow"
        :label="tableHeadFields[9].label"
        :width="tableHeadFields[9].width"
        :align="tableHeadFields[9].align"
        :prop="tableHeadFields[9].prop">
        <template slot-scope="scope">
          <span v-if="scope.row.status === 0">锁定中</span>
          <span v-if="scope.row.status === 1">使用成功</span>
        </template>
      </el-table-column>
    </el-table>
    <!-- end 数据Table -->

    <!-- 分页模块 -->
    <pagination v-show="total > 0" :total="total" :page.sync="currentPage" :limit.sync="pageSize" />
    <!-- end 分页模块 -->
  </div>
</template>
<script>
  import Pagination from '@/views/mixins/pagination'
  import Filter from '@/views/mixins/filter'
  import ModalMixins from '@/views/mixins/modal'
  import CommonInfo from '@/views/mixins/commonInfo'
  import ContentHeader from '@/components/ContentHeader'
  import { logList, merchantList } from '@/api/consumeLimit'
  import moment from 'moment'
  const { export_json_to_excel } = require('../../../vendor/Export2Excel')

  export default {
    name: 'Discount',

    components: { ContentHeader },

    mixins: [Pagination, Filter, ModalMixins, CommonInfo],

    data() {
      return {
        /**
         * contentHeader组件配置
         */
        settings: {
          left: {
            totalItemShow: true,
            searchShow: true,
            filterToggleShow: true,
            tableHeadToggleShow: false,
            searchPlaceholder: '车牌号'
          },
          right: {
            refreshShow: true,
            sortShow: false,
            addShow: false,
            exportDataShow: true,
            importDataShow: false,
            more: []
          },
          dropDownFilterArray: {
            arr: [
              {
                type: 'dateRange',
                name: 'dateRange'
              },
              // {
              //   type: 'input',
              //   name: 'refTransNo',
              //   placeholder: '业务订单号'
              // },
              {
                type: 'input',
                name: 'refOrderNo',
                placeholder: '业务交易流水号'
              },
              {
                type: 'select',
                name: 'mchId',
                placeholder: '请选择商户号',
                options: []
              },
              {
                type: 'select',
                name: 'status',
                placeholder: '状态',
                options: [
                  { label: '锁定中', value: 0 },
                  { label: '使用成功', value: 1 }
                ]
              }
            ]
          }
        },

        /**
         * 表格显示字段信息
         */
        tableHeadFields: [
          {
            label: '优免时间',
            isShow: true,
            width: '200',
            align: 'center',
            prop: 'createTime'
          },
          {
            label: '商户名称',
            isShow: true,
            width: '200',
            align: 'center',
            prop: 'mchInfo.name'
          },
          {
            label: '消费订单号',
            isShow: true,
            width: '200',
            align: 'center',
            prop: 'refOrderNo'
          },
          {
            label: '交易流水号',
            isShow: true,
            width: '200',
            align: 'center',
            prop: 'refTransNo'
          },
          {
            label: '停车订单号',
            isShow: true,
            width: '200',
            align: 'center',
            prop: 'parkingOrder.orderId'
          },
          {
            label: '车牌号',
            isShow: true,
            width: '200',
            align: 'center',
            prop: 'parkingOrder.carNumber'
          },
          {
            label: '车牌照片',
            isShow: true,
            width: '200',
            align: 'center',
            prop: 'parkingOrder.cloudEnterPicture'
          },
          {
            label: '优免金额（元）',
            isShow: true,
            width: '200',
            align: 'center',
            prop: 'limitPrice'
          },
          {
            label: '实际优免金额（元）',
            isShow: true,
            width: '200',
            align: 'center',
            prop: 'actualDiscountPrice'
          },
          {
            label: '状态',
            isShow: true,
            width: '200',
            align: 'center',
            prop: 'status'
          }
        ],

        /**
         * 权限信息
         */
        permissions: {
          export: 'fee-record:merchant-detail:export'
        }
      }
    },

    mounted() {
      this.getList()
      this.getMerchantList()
    },

    methods: {
      /**
       * 获取商户列表
       */
      getMerchantList() {
        const queryData = {}
        queryData.communityId = this.communityId
        merchantList(queryData).then((res) => {
          const mchList = []
          res.data.forEach((item) => {
            mchList.push({ label: item.name, value: item.id })
          })
          this.settings.dropDownFilterArray.arr[3].options.splice(
            0,
            this.settings.dropDownFilterArray.arr[3].options.length,
            ...mchList
          )
          console.log()
        })
      },

      /**
       * 获取列表数据
       */
      getList() {
        const _this = this
        this.loading = true

        // 组装查询条件
        const queryData = {}
        queryData.current = this.currentPage
        queryData.size = this.pageSize
        queryData.parkingId = this.pid
        if (this.filterData.status !== undefined) {
          queryData.status = this.filterData.status
        }
        this.filterData.searchStr && (queryData.carNumber = this.filterData.searchStr)
        if (this.filterData.dateRange) {
          queryData.fromTime = moment(this.filterData.dateRange[0]).format('YYYY-MM-DD HH:mm:ss')
          queryData.toTime = moment(this.filterData.dateRange[1]).format('YYYY-MM-DD HH:mm:ss')
        } else {
          queryData.fromTime = moment(new Date()).format('YYYY-MM-DD 00:00:00')
          queryData.toTime = moment(new Date()).add(1, 'days').format('YYYY-MM-DD 00:00:00')
        }

        logList(queryData)
          .then((res) => {
            _this.dataList = res.data.records
            _this.total = res.data.total
          })
          .catch((err) => {
            _this.noticeError(err.message)
          })
          .finally(() => {
            _this.loading = false
          })
      },

      /**
       * 刷新页面
       */
      handleRefresh() {
        this.getList()
      },

      /**
       * 导出数据
       */
      handleExport() {
        const _this = this
        this.$confirm('是否导出商停优免明细?', '警告', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
          .then(() => {
            // 组装查询条件
            const queryData = {}
            queryData.current = 1
            queryData.size = 999
            queryData.parkingId = this.pid
            this.filterData.searchStr && (queryData.searchStr = this.filterData.searchStr)
            this.filterData.refTransNo && (queryData.refTransNo = this.filterData.refTransNo)
            this.filterData.refOrderNo && (queryData.refOrderNo = this.filterData.refOrderNo)
            this.filterData.mchId && (queryData.mchId = this.filterData.mchId)
            if (this.filterData.dateRange) {
              queryData.fromTime = moment(this.filterData.dateRange[0]).format('YYYY-MM-DD HH:mm:ss')
              queryData.toTime = moment(this.filterData.dateRange[1]).format('YYYY-MM-DD HH:mm:ss')
            } else {
              queryData.fromTime = moment(new Date()).format('YYYY-MM-DD 00:00:00')
              queryData.toTime = moment(new Date()).add(1, 'days').format('YYYY-MM-DD 00:00:00')
            }
            // if (this.filterData.dateRange) {
            //   queryData.startTime = moment(this.filterData.dateRange[0]).format('YYYY-MM-DD')
            //   queryData.endTime = moment(this.filterData.dateRange[1]).format('YYYY-MM-DD')
            // }
            logList(queryData)
              .then((res) => {
                console.log(res, 'shifoudaochu')
                require.ensure([], () => {
                  const tHeader = []
                  const filterVal = []
                  this.tableHeadFields.forEach((item) => {
                    tHeader.push(item.label)
                    filterVal.push(item.prop)
                  })
                  const data = res.data.records.map((v) => {
                    return filterVal.map((j) => {
                      if (j === 'parkingOrder.orderId') {
                        return v.parkingOrder.orderId
                      } else if (j === 'parkingOrder.carNumber') {
                        return v.parkingOrder.carNumber
                      } else if (j === 'parkingOrder.cloudEnterPicture') {
                        return v.parkingOrder.cloudEnterPicture
                      } else {
                        return v[j]
                      }
                    })
                  })
                  console.log(data)
                  console.log('======')
                  export_json_to_excel(tHeader, data, '商停优免明细')
                })
                _this.noticeSuccess('导出成功')
              })
              .catch((err) => {
                _this.noticeError(err.message)
              })
          })
          .catch((err) => {
            _this.noticeError(err.message)
          })
      }
    }
  }
</script>
